import React, { Component, createRef } from 'react'
import Textfield from '@atlaskit/textfield';
import InlineDialog from '@atlaskit/inline-dialog';
import { Querys } from '../../../database/base';
import FavLink from '../../../views/pages/painel/components/FavLink';
import Button from '@atlaskit/button/standard-button';
import CrossIcon from '@atlaskit/icon/glyph/cross'
import Tooltip from '@atlaskit/tooltip';

const app = JSON.parse(localStorage.getItem('_suricatoo_app'))
const user = JSON.parse(localStorage.getItem('_suricatoo_user'))
let accessToken = user?.access_token ?? ''
if (accessToken !== '') {
    accessToken = `?p=${accessToken}`
}

let zoom = localStorage.getItem('_minzi_zoom') ?? 100
if (zoom > 200)
    zoom = 100
if (zoom < 50)
    zoom = 100


export default class GlobalSearch extends Component {
    state = {
        searching: false,
        tm: null,
        q: '',
        data: null,
        isSearching: false,
        indexSelect: -1
    }

    constructor(props) {
        super(props)
        this.textRef = createRef();
    }

    toogle = () => {
        if (!this.state.searching === true) {
            this.textRef.current.focus()
        } else {
            this.textRef.current.blur()
        }
        this.setState({ searching: !this.state.searching })
    }
    search = () => {
        this.setState({ isSearching: true })
        Querys.Person.GlobalSearch(this.state.q).then(({ data }) => {
            this.setState({ data: data ?? [], isSearching: false, indexSelect: -1 })
        })
    }

    componentDidMount() {
        document.addEventListener("keydown", (e) => this.keyPress(e), false)
        this.search()
        debugger
        if (document.body.style.zoom === '') document.body.style.zoom =  '100%'
        let zoomBrowser = parseInt(document.body.style.zoom.replaceAll("%", ""))
        if (zoomBrowser !== zoom) {
            zoom = zoomBrowser
            if (zoom > 150) zoom = 100
            if (zoom < 50) zoom = 100
        }
        this.setZoom(zoom)
    }

    setZoom(zoom) {
        document.body.style.zoom = `${zoom}%`
        localStorage.setItem('_minzi_zoom', zoom)
    }

    keyPress(e) {
        if (e.ctrlKey && e.code === "Space") this.toogle()

        if (e.ctrlKey && e.key === '+') {
            if (document.body.style.zoom === '') document.body.style.zoom =  '100%'
            let zoom = parseInt(document.body.style.zoom.replaceAll("%", ""))
            if (zoom > 150) zoom = 100
            else zoom += 10
            this.setZoom(zoom)

        }
        if (e.ctrlKey && e.key === '-') {
            if (document.body.style.zoom === '') document.body.style.zoom =  '100%'
            let zoom = parseInt(document.body.style.zoom.replaceAll("%", ""))
            if (zoom < 50) zoom = 100
            else zoom -= 10
            this.setZoom(zoom)
        }
        if (e.ctrlKey && e.key === '0') {
            this.setZoom(100)
        }
    }

    navigate(e) {
        if (e.key === 'Enter' && this.state.indexSelect >= 0) {
            var link = window.document.createElement("a");
            link.setAttribute("href", this.state.data[this.state.indexSelect].link + accessToken);
            //link.setAttribute("target", "_blank");
            link.click();
            return this.state.indexSelect
        }
        if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') {
            return this.state.indexSelect
        }
        if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && this.state.indexSelect === -1) {
            return 0
        }
        let indexSelect
        if (e.key === 'ArrowUp') {
            indexSelect = this.state.indexSelect - 1
        } else if (e.key === 'ArrowDown') {
            indexSelect = this.state.indexSelect + 1
        }
        const element = document.getElementById(`global-search-row-${indexSelect}`)
        if (element) element.scrollIntoView({ block: "center" })
        return indexSelect
    }

    remove(item) {
        Querys.Person.FavLinkUpdate({
            name: item.name,
            link: item.link,
            action: 'remove'
        }).then(() => {
            this.search()
        })
    }

    render() {
        let lastType = ''
        return (
            <div className='ml-1'>
                <InlineDialog
                    onContentBlur={this.toogle}
                    onClose={this.toogle}
                    isOpen={this.state.searching}
                    placement='bottom-start' content={
                        <div className='global-search-box'>
                            {this.state.isSearching && <small>Pesquisando...</small>}
                            {!this.state.isSearching && this.state.data && <div>
                                {this.state.data.length === 0 && <small>Nenhum item encontrado</small>}
                                <table resposive className='w-100'>
                                    <tbody>
                                        {this.state.data.map((item, index) => {
                                            if (item.type !== lastType) {
                                                lastType = item.type
                                                return <>
                                                    <tr key={`header-${item.type}`}><td colSpan={2}><small><b>{item.type}</b></small></td></tr>
                                                    <tr key={`row-${index}`} id={`global-search-row-${index}`}>
                                                        <td className={`global-search-line ${index === this.state.indexSelect ? 'global-search-line-active' : ''}`}>
                                                            <div className='d-flex justify-content-between'>
                                                                <a href={`${item.link}${accessToken}`}>
                                                                    {item.name}
                                                                </a>
                                                                {item.type === 'Links favoritos' &&
                                                                    <div className='global-search-remove'>
                                                                        <Tooltip content='Remover'>
                                                                            <a href='#' onClick={() => this.remove(item)}><CrossIcon size='small' /></a>
                                                                        </Tooltip>
                                                                    </div>}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            }
                                            return <tr key={`row-${index}`}>
                                                <td className={`global-search-line ${index === this.state.indexSelect ? 'global-search-line-active' : ''}`}
                                                    id={`global-search-row-${index}`}>
                                                    <div className='d-flex justify-content-between'>
                                                        <a href={`${item.link}${accessToken}`}>
                                                            {item.name}
                                                        </a>
                                                        {item.type === 'Links favoritos' &&
                                                            <div className='global-search-remove'>
                                                                <Tooltip content='Remover'>
                                                                    <a href='#' onClick={() => this.remove(item)}><CrossIcon size='small' /></a>
                                                                </Tooltip>
                                                            </div>}
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                            {!this.state.isSearching && this.state.data === null && <div>
                                <small>Digite um card, ou projeto ou sprint para pesquisar para navegar</small>
                            </div>}
                        </div>
                    }>
                    <div className='d-flex'>
                        <Textfield
                            placeholder='Navegar para (ctrl + espaço)'
                            className='global-search-textbox'
                            name='search'
                            ref={this.textRef}
                            onFocus={this.toogle}
                            onKeyDown={e => {
                                const indexSelect = this.navigate(e)
                                if (indexSelect !== this.state.indexSelect)
                                    this.setState({ indexSelect })
                            }}
                            onChange={e => {
                                if (this.state.tm !== null)
                                    clearTimeout(this.state.tm)
                                this.setState({ q: e.target.value, tm: setTimeout(this.search, 350) })
                            }} />
                        <FavLink />
                    </div>
                </InlineDialog>
            </div>
        )
    }
}
