import axios from "axios"
import { del, get, post, put } from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.WikiURL}/api`
}

export const GetDocument = (companyCode, document) => {
    const auth = axios.defaults.headers.common['Authorization']
    return get(`${getUrl()}/document/${companyCode}/${document}?p=${auth}`)
}

export const GetHistory = (companyCode, document) => {
    const auth = axios.defaults.headers.common['Authorization']
    return get(`${getUrl()}/document/${companyCode}/${document}/history?p=${auth}`)
}

export const GetPermissions = (companyCode, document) => {
    const auth = axios.defaults.headers.common['Authorization']
    return get(`${getUrl()}/document/${companyCode}/${document}/permissions?p=${auth}`)
}

export const SetPermissions = (companyCode, data) => {
    const auth = axios.defaults.headers.common['Authorization']
    return put(`${getUrl()}/document/${companyCode}/permissions?p=${auth}`, data)
}

export const UpdateDocument = (companyCode, document, data) => {
    const auth = axios.defaults.headers.common['Authorization']
    return put(`${getUrl()}/document/${companyCode}/${document}?p=${auth}`, data)
}

export const GetTree = (companyCode) => {
    const auth = axios.defaults.headers.common['Authorization']
    return get(`${getUrl()}/${companyCode}/wiki?p=${auth}`)
}

export const ExportPDF = (companyCode, document) => {
    const auth = axios.defaults.headers.common['Authorization']
    return get(`${getUrl()}/document/${companyCode}/${document}/pdf?p=${auth}`)
}

export const DeleteDocument = (companyCode, document) => {
    const auth = axios.defaults.headers.common['Authorization']
    return del(`${getUrl()}/document/${companyCode}/${document}/delete?p=${auth}`)
}

export const CopyDocument = (companyCode, document, newDocument) => {
    const auth = axios.defaults.headers.common['Authorization']
    return put(`${getUrl()}/document/${companyCode}/${document}/${newDocument}/copy?p=${auth}`)
}

export const SaveTree = (companyCode, data) => {
    const auth = axios.defaults.headers.common['Authorization']
    return put(`${getUrl()}/${companyCode}/wiki?p=${auth}`, data)
}

export const DeleteHistory = (companyCode, document, documentVersionID) => {
    const auth = axios.defaults.headers.common['Authorization']
    return del(`${getUrl()}/document/${companyCode}/${document}/history/${documentVersionID}/delete?p=${auth}`)
}

export const RevertHistory = (companyCode, document, documentVersionID) => {
    const auth = axios.defaults.headers.common['Authorization']
    return put(`${getUrl()}/document/${companyCode}/${document}/history/${documentVersionID}/revert?p=${auth}`)
}